/**
 *  Parameters for the app to start with
 */

import z from 'zod'

const typeSchema = z.enum(['SP', 'DP'])

const zodParamToBool = z.coerce
  .string()
  .optional()
  .transform((val) => {
    if (val?.toLowerCase() === 'true') {
      return 1
    }
    if (val?.toLowerCase() === 'false') {
      return 0
    }
    return Number(val || 0)
  })
  .pipe(z.coerce.boolean())

const refractionSchema = z.object({
  r_sph: z.coerce.number(),
  l_sph: z.coerce.number(),
  r_cyl: z.coerce.number(),
  l_cyl: z.coerce.number(),
  r_axis: z.coerce.number(),
  l_axis: z.coerce.number(),
  prism: zodParamToBool,
  right_na_te: z.string().optional(),
  left_na_te: z.string().optional(),
  right_ha_ba: z.string().optional(),
  left_ha_ba: z.string().optional(),
  r_prism1: z.coerce.number().optional(),
  l_prism1: z.coerce.number().optional(),
  r_prism2: z.coerce.number().optional(),
  l_prism2: z.coerce.number().optional(),
  r_add: z.coerce.number(),
  l_add: z.coerce.number(),
  r_pd: z.coerce.number(),
  l_pd: z.coerce.number(),
})

export const responseRefractionSchema = z.object({
  r_sph: z.coerce.number(),
  l_sph: z.coerce.number(),
  r_cyl: z.coerce.number(),
  l_cyl: z.coerce.number(),
  r_axis: z.coerce.number(),
  l_axis: z.coerce.number(),
  prism: z.coerce.boolean(),
  right_na_te: z.string().optional(),
  left_na_te: z.string().optional(),
  right_ha_ba: z.string().optional(),
  left_ha_ba: z.string().optional(),
  r_prism1: z.coerce.string().optional(),
  l_prism1: z.coerce.string().optional(),
  r_prism2: z.coerce.string().optional(),
  l_prism2: z.coerce.string().optional(),
  r_add: z.coerce.number(),
  l_add: z.coerce.number(),
  r_pd: z.coerce.number(),
  l_pd: z.coerce.number(),
  // r_fh: z.coerce.number(),
  // l_fh: z.coerce.number(),
})

export const orderSchema = z
  .object({
    session_id: z.coerce.string(),
    frame: z.string(),
    frame_type: z.string(),
    frame_price: z.coerce.number().optional(),
    r_fh: z.coerce.number().optional(),
    l_fh: z.coerce.number().optional(),
    vbox: z.coerce.number().optional(),
    y_ext: z.coerce.number().optional(),
  })
  .passthrough()

export const initialParamsSchema = z
  .object({
    store_ref: z.string(),
    customer_ref: z.string(),
    type: typeSchema,
    ref: refractionSchema,
    orders: z.array(orderSchema),
    step: z.string().optional(),
    strategy: z.string().optional(),
    birthday: z.coerce.date(),
    locale: z.string().optional().default('de'),
    dev: zodParamToBool.optional(),
    initialStep: z.string().optional(),
    name: z.string().optional(),
    strategy_input: z.string().optional(),
    '2P': z.string().optional(),
  })
  .passthrough()

export const paramsUpdateSchema = initialParamsSchema
  .omit({
    ref: true,
  })
  .deepPartial()

export type InitialParams = z.infer<typeof initialParamsSchema>
export type Refraction = z.infer<typeof refractionSchema>
export type Order = z.infer<typeof orderSchema>
