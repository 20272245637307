import { StepHistory } from '@type/StepHistory'
import { InitialParams, responseRefractionSchema } from '@type/initialParams'
import getLens from '@utils/getLens'
import getResponseVariables, {
  VariableConfig,
} from '@utils/getResponseVariables'
import {
  getFlatOptionsValues,
  getSummary,
  getValuesFromHistory,
} from '@utils/optionUtils'

import axios, { AxiosError } from 'axios'
import eventApi from './eventApi'
import i18next from 'i18next'
import { captureMessage } from '@sentry/react'
import { endpoints } from '@cwa/types'

const getSelectedTlKey = (
  item: ReturnType<typeof getSummary>['selected'][number]
) => `attributes.${item.attribute}`

// HACK: Because native app is weird ¯\_(ツ)_/¯
const mapSelected = (
  item: ReturnType<typeof getSummary>['selected'][number]
) => {
  return {
    price: item.price,
    // @ts-expect-error i18next key is dynamic here
    attribute: i18next.t(getSelectedTlKey(item)),
    value: item.label || item.value,
  }
}

const getOrder = ({
  index,
  params,
  history,
  variablesConfig,
}: {
  index: number
  params: InitialParams
  history: StepHistory
  variablesConfig: VariableConfig[]
}) => {
  const { ref, orders, ...rest } = params
  const orderNumber = index + 1
  const outputValues = getValuesFromHistory(history, true, orderNumber)
  const values = getFlatOptionsValues(
    getValuesFromHistory(history, false, orderNumber)
  )
  const allValues: Record<string, string | number | Date | boolean> = {
    ...rest,
    ...values,
    order: `${orderNumber}`,
  }
  // We skip the order if the skipOrder value is set and is gte the current order number
  const skipOrder =
    allValues.skipOrder &&
    parseInt(allValues.skipOrder as string) >= orderNumber

  const orderVariableConfig = variablesConfig.filter((v) => v.scope === 'order')
  // For now we don't add the refraction to the order we need to get the initial refraction from the params
  if (skipOrder) {
    return {
      ...orders[index],
      // For now we want to add only the strategy_ref here
      strategy_ref: getResponseVariables(orderVariableConfig, allValues)
        ?.strategy_ref,
    }
  }

  const refraction = responseRefractionSchema.safeParse(ref)
  const summary = getSummary(outputValues, false)
  const { selected } = summary

  return {
    ...orders[index],
    refraction: refraction?.success ? refraction.data : ref,
    lens: getLens({
      ...values,
      // Rename function to modifier (function is reserved)
      modifier: values.function || '',
    }),
    // For now not sure what to do with this
    lifestype: '',
    selected: selected
      .filter((item) => i18next.exists(getSelectedTlKey(item)))
      .map(mapSelected),
    ...getResponseVariables(orderVariableConfig, allValues),
  }
}

export const getPayload = ({
  params,
  history,
  variablesConfig,
}: {
  params: InitialParams
  history: StepHistory
  variablesConfig: VariableConfig[]
}) => {
  const { ref, ...rest } = params
  const values = getFlatOptionsValues(getValuesFromHistory(history, false))

  const globalVariableConfig = variablesConfig.filter(
    (v) => v.scope === 'global'
  )
  const payload = {
    ...rest,
    ...getResponseVariables(globalVariableConfig, values),
    orders: params.orders.map((_, index) =>
      getOrder({
        index,
        params,
        history,
        variablesConfig,
      })
    ),
  }

  return payload
}

const postResult = async ({
  params,
  history,
  onSuccess,
  onError,
  variablesConfig,
}: {
  params: InitialParams
  history: StepHistory
  variablesConfig: VariableConfig[]
  onSuccess?: () => void
  onError?: (err: AxiosError) => void
}) => {
  try {
    // await axios.post(AppConfig.resultApi.url, payload, {
    //   headers: {
    //     'x-api-key': AppConfig.resultApi.key,
    //     'X-Country-Code': 'DE',
    //   },
    // })
    const payload = getPayload({ params, history, variablesConfig })

    console.log('Posting result', payload)
    captureMessage('Posting result', {
      extra: payload,
    })
    axios.post(endpoints.logs.result, payload, {
      baseURL: '/api',
    })
    eventApi.postResult(payload)

    onSuccess?.()
  } catch (e) {
    console.error('Error posting results', e)
    onError?.(e as AxiosError)
  }
}

export default postResult
